$gb-scope: '.ncaftmkt';

@import './node_modules/@nc-gb-morpheus/alerts/index';
@import './node_modules/@nc-gb-morpheus/buttons/index';
@import './node_modules/@nc-gb-morpheus/checkboxes/index';
@import './node_modules/@nc-gb-morpheus/inputs/index';
@import './node_modules/@nc-gb-morpheus/loaders/index';
@import './node_modules/@nc-gb-morpheus/modals/index';
@import './node_modules/@nc-gb-morpheus/tooltips/index';
@import './node_modules/@nc-green-beard/badge/index';
@import './node_modules/@nc-green-beard/hero/index';
@import './node_modules/@nc-green-beard/typography/index';
@import './node_modules/@nc-green-beard/tables/index';

:root {
  --gb-modal-overlay: #{$gb-modal-overlay};
}

.MuiPopper-root[data-popper-placement*="top"] .MuiPopper-arrow {
  bottom: $gb-spacing-brand;
  margin-left: -$gb-spacing-brand;
}

.MuiPopper-root[data-popper-placement*="bottom"] .MuiPopper-arrow {
  top: -$gb-spacing-brand;
  margin-left: -$gb-spacing-brand;
}

.MuiPopper-root[data-popper-placement*="right"] .MuiPopper-arrow {
  margin-top: -$gb-spacing-brand;
  left: -$gb-spacing-brand;
}

.MuiPopper-root[data-popper-placement*="left"] .MuiPopper-arrow {
  right: $gb-spacing-brand;
}
