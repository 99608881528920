@tailwind base;

details summary::-webkit-details-marker {
  display: none;
}

main {
  min-height: 100vh;
  padding-bottom: 1px;
}

main * {
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

main :is(button, input, optgroup, select, textarea) {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

.ncaftmkt {
  --PhoneInputCountrySelectArrow-color: black;
  @apply tw-font-sans tw-text-gray-600;
  display: block;
  background-color: #fcfcfc;
}

.ncaftmkt :is(h1, h2, h3, h4, h5, h6) {
  margin: 0;
}

.ncaftmkt button {
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
}

.ncaftmkt button:focus {
  @apply tw-brand-outline;
}

.ncaftmkt button:focus:not(:focus-visible) {
  outline: none;
}

.ncaftmkt ol,
.ncaftmkt ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ncaftmkt p {
  margin: 0;
}

.ncaftmkt table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ncaftmkt svg {
  display: block;
}

.ncaftmkt .gb-hero__image-holder img {
  object-position: 33% 0;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'] {
  background-color: #add3d3 !important;
}

@screen sm {
  .ncaftmkt .gb-hero__image-holder img {
    object-position: initial;
  }
}

@tailwind components;

@tailwind utilities;

@layer components {
  .tw-domain-metric-rows:nth-child(9n + 1) {
    background-color: #ffedd2;
  }

  .tw-domain-metric-cols:nth-child(9n + 1) {
    background-color: #ffedd2;
  }

  .tw-domain-metric-rows:nth-child(9n + 2) {
    background-color: #ffdc98;
  }

  .tw-domain-metric-cols:nth-child(9n + 3) {
    background-color: #ffdc98;
  }

  .tw-domain-metric-rows:nth-child(9n + 3) {
    background-color: rgb(245, 205, 174);
  }

  .tw-domain-metric-cols:nth-child(9n + 5) {
    background-color: rgb(245, 205, 174);
  }

  .tw-domain-metric-rows:nth-child(9n + 4) {
    background-color: #d7f6e7;
  }

  .tw-domain-metric-cols:nth-child(9n + 2) {
    background-color: #d7f6e7;
  }

  .tw-domain-metric-rows:nth-child(9n + 5) {
    background-color: #b7e9e9;
  }

  .tw-domain-metric-cols:nth-child(9n + 4) {
    background-color: #b7e9e9;
  }

  .tw-domain-metric-rows:nth-child(9n + 6) {
    background-color: #dcd4e3;
  }

  .tw-domain-metric-cols:nth-child(9n + 6) {
    background-color: #dcd4e3;
  }

  .tw-domain-metric-rows:nth-child(9n + 7) {
    background-color: #d7f3f6;
  }

  .tw-domain-metric-cols:nth-child(9n + 9) {
    background-color: #d7f3f6;
  }

  .tw-domain-metric-rows:nth-child(9n + 8) {
    background-color: #b6dce9;
  }

  .tw-domain-metric-cols:nth-child(9n + 7) {
    background-color: #b6dce9;
  }

  .tw-domain-metric-rows:nth-child(9n + 9) {
    background-color: #c2bfef;
  }

  .tw-domain-metric-cols:nth-child(9n + 8) {
    background-color: #c2bfef;
  }

  /* .tw-domain-metric-rows:nth-child(n + 7) {
    filter: hue-rotate(220deg);
  }

  .tw-domain-metric-cols:nth-child(n + 7) {
    filter: hue-rotate(140deg);
  } */

  .tw-filters {
    @apply tw-relative tw-z-0 tw-rounded-lg !important;
  }

  .tw-filters::before {
    @apply tw-absolute tw-bg-white tw-rounded-lg !important;
    content: '';
    z-index: -1;
    top: -3rem;
    left: 0;
    width: 6.5rem;
    height: 4rem;
  }

  @screen sm {
    .tw-nav-tab {
      border-bottom: 1px solid transparent;
    }

    .tw-nav-tab--active {
      @apply tw-border-secondary !important;
    }
  }

  .tw-paper {
    @apply tw-bg-white tw-rounded-lg tw-shadow-card !important;
  }

  .tw-wrap {
    @apply sm:tw-px-4;
  }

  .tw-wrap-inner {
    @apply tw-mx-auto tw-max-w-screen-2xl !important;
  }
}

@layer utilities {
  .tw-brand-shadow {
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.15));
  }

  .tw-brand-outline {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
